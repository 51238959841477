import { atom } from 'recoil'

export const artistDataState = atom({
  key: 'artistDataState',
  default: {
    public_id: '',
    display_name: '',
    comment: '',
    gift_detail: '',
    icon_uri: '',
  },
})
